import React, { useState, useEffect, useRef } from 'react';
import '../styles/Carousel.css';
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import {useMediaQuery} from 'react-responsive';

const Carousel = (props) => {
  const { currentIndex, setCurrentIndex, style, images, imageStyle } = props;

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const swipeRef = useRef(null);

  const handlePrev = () => {
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : images.length - 1);
  }

  const handleNext = () => {
    setCurrentIndex(currentIndex < images.length - 1 ? currentIndex + 1 : 0);
  }

  // Swipe Handlers
  // let xDown = null;
  // const handleTouchStart = (e) => {
  //   xDown = e.touches[0].clientX;
  // };
  // const handleTouchMove = (e) => {
  //   if (!xDown) {
  //     return;
  //   }
  //   let xUp = e.touches[0].clientX;
  //   let xDiff = xDown - xUp;
  //   if (Math.abs(xDiff)) {
  //     if (xDiff > 0) {
  //       handleNext();
  //     } else {
  //       handlePrev();
  //     }
  //   }
  //   xDown = null;
  // };
  //
  // useEffect(() => {
  //   const swipeElement = swipeRef.current;
  //   swipeElement.addEventListener('touchstart', handleTouchStart, false);
  //   swipeElement.addEventListener('touchmove', handleTouchMove, false);
  //
  //   return () => {
  //     swipeElement.removeEventListener('touchstart', handleTouchStart);
  //     swipeElement.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);

  return (
    <div style={style} className="carousel" ref={swipeRef}>
      {currentIndex !== 0 &&
        <button style={{ width: 30, height: 30, borderRadius: 40, backgroundColor: 'rgba(0,0,0,0.7)' }} className="black-fill carousel__button carousel__button--prev" onClick={handlePrev}>
          <IconContext.Provider value={{
              size: 20,
              color: 'white',
            }}>
            {Icons.arrowLeft}
          </IconContext.Provider>
        </button>
      }
      <div className="carousel__images" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, i) => {

          // console.log(image);
          //
          // console.log(image.slice(-3));
          // console.log(image.slice(-3) === 'mp4');

          return (
          image.slice(-3) !== 'mp4' ?
          <img
            style={{ objectFit: 'cover', width: '100%', height: '100%', ...imageStyle }}
            className="carousel__image"
            src={image}
            key={image}
            loading="lazy"
          />
          :
          <video
            key={image}
            style={{ objectFit: 'cover', width: '100%', height: '100%', ...imageStyle }}
            preload="metadata"
            controls="controls"
            className="carousel__image"
            playsInline={isMobile
              ? false
              : true}>
            <source src={image + '#t=0.001'} type="video/mp4"/>
          </video>
        );
        }
      )}
      </div>
      {currentIndex < images.length - 1 &&
        <button style={{ width: 30, height: 30, borderRadius: 40, backgroundColor: 'rgba(0,0,0,0.7)' }} className="carousel__button carousel__button--next" onClick={handleNext}>
          <IconContext.Provider value={{
              size: 24,
              color: 'white',
            }}>
            {Icons.arrowRight}
          </IconContext.Provider>
        </button>
      }
      {props.showCounter &&
        <div style={{ transform: 'translateX(-50%)', left: '50%', borderRadius: 5, position: 'absolute', top: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8, backgroundColor: 'rgba(0,0,0,0.7)' }}>
          <div style={{ textAlign: 'center' }} className="body4 white">{(currentIndex + 1) + '/' + images.length}</div>
        </div>
      }
    </div>
  );
};

export default Carousel;
